import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'

const CurrentPill = ({ currentPill, images }) => {
  const { name, numPills, img, width } = currentPill
  return (
    <div className="block tc">
      <h2 className="mv4">Put {numPills} {name} <br/> in each time of day</h2>
      <img
        alt={name}
        src={images[img] || '/images/nothing.png'}
        height="256"
        width={width || "256"}
      />
    </div>
  )
}

const Tutorial = ({ allPills, images, showTutorialModal, setShowTutorialModal }) => {
  const sections = ['Morning', 'Noon', 'Night']
  const hours = ['9', '12', '19']

  const [currentSection, setCurrentSection] = useState(0)
  const [groupedPills, setGroupedPills] = useState([[], [], []])
  const [showNextPill, setShowNextPill] = useState(true)

    useEffect(() => {
        if(currentSection){
            setShowNextPill(true)
        }
    }, [currentSection])

  useEffect(() => {
    const groups = { '9': [], '12': [], '19': [] }

    allPills.forEach(pill => {
      pill.hour.split(',').forEach(hour => {
        if (groups[hour] && pill.numPills != 0) groups[hour].push(pill)
      })
    })

    setGroupedPills(hours.map(h => [...groups[h]]))
  }, [allPills])

  const handleNext = () => {
    setGroupedPills(prev => {
      const updated = prev.map(group => [...group])
      updated[currentSection].pop()

      // If no more pills in current section, move to next (if possible)
      if (updated[currentSection].length === 0 && currentSection < sections.length - 1) {
        setCurrentSection(cs => cs + 1)
      }

      return updated
    })
  }

  const currentPills = groupedPills[currentSection]
  const currentPill = currentPills[currentPills.length - 1]
  const finished = currentSection == sections.length-1 && !currentPill
  return (<Modal
    title="Fill Pill Box"
    centered="true"
    open={showTutorialModal}
    onOk={showNextPill ? () => setShowNextPill(false) : handleNext}
    okText="Next"
    cancelText="Finished"
    onCancel={finished && (() => setShowTutorialModal(false))}
  >
    {!showNextPill ? (
        <div className="block justify-center p-4 mh4 tc">
            <h1>{currentPill ? sections[currentSection] : "You're finished for this week!"}</h1>
            {currentPill && (
                <CurrentPill currentPill={currentPill} images={images} />
            )}
        </div>
    ) : (<div className="block tc">
            <h2>Let's fill the <br/><h1 className="b">{sections[currentSection]}</h1> pill box sections. Click Next.</h2>
        </div>) }
</Modal>)
}

export default Tutorial