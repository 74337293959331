export const backendURL = 'http://127.0.0.1:5000'

// **************************************************************************

// encounter for therapeutic drug level monitoring?
/*
-- FROM VISIT TO CLINIC 02/06/2024-- 
HEART, ANTI-HLA ANTIBODY SCREEN CDC, POSTTX
    Heart replaced by transplant
HLAAB: Other
HLAAB B Cell, IgG, Other
HLAAB B Cell Ig Total, Other
HLAAB T Cell, IgG, Other
HLAAB T Cell Ig Total, Other
HLAAB B Cell IgM, Other
HLAAB T Cell IgM, Other
DSA by Luminex SAB POSTTX
    Heart replaced by transplant
HLAAB Class I, Luminex Screening, Other
HLAAB Class II, Luminex Screening, Other
HLAAB Class I, Luminex Single Antigen, Other
HLAAB Class II, Luminex Single Antigen, Other
CPRA for A1C, Whole Blood for Heart replaced by transplant

Hemoglobin A1C
Vitamin D, 25-Hydroxy (Various methods) for Heart replaced by transplant
Thyroid Stimulating Hormone (TSH) w/Free Thyroxine (FT4) RxR for Heart replaced by transplant
Tacrolimus for Heart replaced by transplant
Immunosup Curr Function Assay, Blood for Heart replaced by transplant
ProBeta (B-Type) Natriuretic Peptide, NT for Heart replaced by transplant
Biomarker, Metabolic Panel for Heart replaced by transplant
CBC Panel w/Platelets + Differential for Heart replaced by transplant
Automated Diff
Epstein Barr Virus (EBV) , PCR, Plasma for Heart replaced by transplant
Cytomegalovirus (CMV) DNA Qn PCR for Heart replaced by transplant

*/
export const medicalConditions = ["heart transplant infection", "cardiac allograft vasculopathy", "diabetes mellitus type 2 with complications", "dyslipidemia"]

export const allPills = [
  {
    "img": "cellcept.png",
    "name": "Cellcept (Mycophenolate Mofetil)",
    "dosage": "250mg",
    "width": "400",
    "numPills": 2,
    "comments": "1000mg per day. Prevents Rejection",
    "hour": "9,19",
    "days": "everyday"
  },
  {
    "img": "prednisone.png",
    "name": "Prednisone (Deltasone)",
    "dosage": "2.5mg",
    "numPills": 1,
    "comments": "Prevents rejection.",
    "hour": "9",
    "width": "300",
    "days": "everyday"
  },
  {
    "img": "prograf_half_mg_aka_tacrolimus.png",
    "name": "Prograf (Tacrolimus, 0.5mg)",
    "dosage": "1mg",
    "numPills": 2,
    "comments": "Prevents rejection. May be used in place of 1mg",
    "hour": "9,19",
    "days": "everyday"
  },
  {
    "img": "norvasc.png",
    "name": "Norvasc (Amlodipine)",
    "dosage": "10mg",
    "numPills": 1,
    "comments": "Treats high blood pressure",
    "hour": "9",
    "days": "everyday"
  },
  {
    "img": "atorvastatin.png",
    "name": "Atorvastatin (Lipitor)",
    "dosage": "20mg",
    "numPills": 1,
    "comments": "For cholesterol",
    "hour": "19",
    "width": "400",
    "days": "everyday"
  },
  {
    "img": "calcium_carbonate_aka_calcium_citrate.png",
    "name": "Calcium Carbonate",
    "dosage": "600mg",
    "numPills": 1,
    "comments": "Take at Noon. Take with food.",
    "hour": "12",
    "days": "everyday"
  },
  {
    "img": "coreg.png",
    "name": "Coreg (Carvedilol)",
    "dosage": "12.5mg",
    "numPills": 1,
    "comments": "Heart Failure",
    "hour": "9,19",
    "days": "everyday"
  },
  {
    "img": "vitamin_b12.png",
    "name": "Vitamin B12 (Cyanocobalamin)",
    "dosage": "1000 mcg",
    "numPills": 1,
    "comments": "For supplementing Humalog",
    "hour": "9",
    "days": "everyday"
  },
  {
    "img": "vitamin_d3.png",
    "name": "Vitamin D3",
    "dosage": "25 mcg",
    "numPills": 1,
    "comments": "bone density",
    "hour": "12",
    "days": "everyday"
  },

  {
    "img": "colace.png",
    "name": "Docusate Sodium (COLACE)",
    "dosage": "100mg",
    "numPills": 1,
    "comments": "Stool softener",
    "hour": "9,19",
    "width": "400",
    "days": "everyday"
  },
  {
    "img": "famotidine.png",
    "name": "Pepcid (Famotidine)",
    "dosage": "20mg",
    "numPills": 1,
    "comments": "Protects your stomach",
    "hour": "12",
    "days": "everyday",
    "width": "400"
  },
  {
    "img": "farxiga.png",
    "name": "Farxiga (dapagliflozin)",
    "dosage": "10mg",
    "numPills": 1,
    "comments": "For blood sugar",
    "hour": "9",
    "width": "400",
    "days": "everyday"
  },
  {
    "img": "icosapent_ethyl.png",
    "name": "Vespa (Icosapent Ethyl)",
    "dosage": "1g",
    "numPills": 1,
    "comments": "High amount of fats in blood.",
    "hour": "9,19",
    "width": "350",
    "days": "everyday"
  },
  {
    "img": "mag_ox.png",
    "name": "Mag Ox (MgO)",
    "dosage": "400mg",
    "numPills": 1,
    "comments": "Take at noon. Nutritional Supplement",
    "hour": "12",
    "width": "240",
    "days": "everyday"
  },
  {
    "img": "metformin.png",
    "name": "Metformin (glucophage)",
    "dosage": "500mg",
    "numPills": 1,
    "comments": "For diabetes",
    "hour": "19",
    "width": "400",
    "days": "everyday"
  },
  {
    "img": "prenatal_vitamin.png",
    "name": "Prenatal Vitamin",
    "dosage": "1g",
    "numPills": 1,
    "comments": "Take on Empty Stomach. Nutritional Supplement. Varied mass.",
    "hour": "9",
    "width": "400",
    "days": "everyday"
  },
  {
    "img": "ozempic.png",
    "name": "Ozempic (semaglutide)",
    "dosage": "0.5ml",
    "numPills": 0,
    "comments": "For diabetes",
    "hour": "19",
    "days": "Tu"
  },
  {
    "img": "potassium_chloride.png",
    "name": "Potassium Chloride ER",
    "dosage": "10 MEQ",
    "numPills": 1,
    "comments": "Potassium Deficiency",
    "hour": "12",
    "width": "400",
    "days": "everyday"
  },
  {
    "img": "telmisartan_40_mg.png",
    "name": "Telmisartan (Micardis)",
    "dosage": "40mg",
    "numPills": 1,
    "comments": "High Blood Pressure",
    "hour": "9",
    "days": "everyday",
    "width": "400"
  },
  {
    "img": "silodosin.png",
    "name": "Silodosin (Rapaflo)",
    "dosage": "4mg",
    "numPills": 1,
    "comments": "Take at noon. For bladder issues",
    "hour": "12",
    "days": "everyday"
  },
]


// before 11/26/24
export const oldPills = [
  {
    "img": "levemir.png",
    "name": "Levemir Solostart (Insulin Detemir)",
    "dosage": "100 units/mL",
    "numPills": 0,
    "comments": "Take in stomach. HOLD if nothing to eat, or if blood sugar < 100. If morning blood sugar is < 100, then decrease the next dose of levemir by 2 units daily. Call clinic if you have questions. ",
    "amount": "12 units",
    "hour": "9",
    "days": "everyday"
  },
  // {
  //   "img": "prograf_1mg.png",
  //   "name": "Prograf (Tacrolimus, 1mg)",
  //   "dosage": "1mg",
  //   "numPills": 1,
  //   "comments": "DO NOT take on mornings of transplant clinic. Hold until after blood is drawn. Prevents rejection.",
  //   "hour": "9,19",
  //   "days": "everyday"
  // },
  // {
  //   "img": "fosamax.png",
  //   "name": "Fosamax (Alendronate)",
  //   "dosage": "70mg",
  //   "numPills": 1,
  //   "comments": "Once a week. Protects bones. Take on empty stomach will full glass of water. Sit upright for 30 minutes after taking.",
  //   "hour": "9",
  //   "days": "M"
  // },
  // {
  //   "img": "torsemide.png",
  //   "name": "Torsemide",
  //   "dosage": "10mg",
  //   "numPills": 1,
  //   "comments": "Diuretic",
  //   "hour": "9",
  //   "days": "everyday"
  // },
  {
    "img": "nothing.png",
    "name": "Humalog",
    "dosage": "6 units",
    "numPills": 0,
    "comments": "Supplemental insulin",
    "hour": "9,19",
    "days": "everyday"
  },

]

// coltracine?

export const appointments = [
  {
    "date": "11/02/2020",
    "purpose": "Aphoresis (new dressing)",
    "notes": ["Will home visit. Needs additional 4 cycles but 2 weeks apart."]
  },
  {
    "date": "11/03/2020",
    "purpose": "Bloodwork",
    "notes": ["Need to call QuestLab. Ask them about the BMP for the Potassium results."]
  },
  {
    "date": "11/12/2020, 2pm",
    "purpose": "Photophresis",
    "notes": ["2 days in a row"]
  },
  {
    "date": "11/17/2020, 9:30am",
    "purpose": "Meeting with Dr. Latif",
    "notes": [""]
  },
  {
    "date": "12/03/2020, 2pm",
    "purpose": "Photophresis",
    "notes": ["2 days in a row"]
  },
  {
    "date": "12/04/2020, 9am",
    "purpose": "Photophresis",
    "notes": ["2 days in a row"]
  },
  {
    "date": "12/23/2020, 2pm",
    "purpose": "Photophresis",
    "notes": ["2 days in a row"]
  },
  {
    "date": "12/24/2020, 9am",
    "purpose": "Photophresis",
    "notes": ["(Tentatively; talk to Jason Lee)"]
  },
]

export const pastAppointments = [
  {
    "date": "Monday, 10/05/2020",
    "purpose": "Bloodwork",
    "notes": [""]
  },
  {
    "date": "Tuesday, 09/22/2020",
    "purpose": "Quest for bloodwork",
    "notes": ["Need to schedule. Would be good to good to book weekly labs unless you go to clinic."]
  },

  {
    "date": "[CANCELLED] Monday 05/19/2020",
    "purpose": "Bloodwork to be done",
    "notes": ["Your last bloodwork had shown positive for CMV"]
  },
  {
    "date": "04/16/2020, 9am",
    "purpose": "Get Echo, bloodwork, no biopsy",
    "notes": [" Call Jason Lee:", " Is Nystatin discontinued?", " What about Colace?", " Is this correct date?"]
  },
  {
    "date": "To Schedule",
    "purpose": "Get IV IG treatment from visiting nurse",
    "notes": [" Nurse hasn't called about IVIG", " Tell them Empire plan renews on 03/31", "Discuss exercises"]
  },
  {
    "date": "Saturday 04/19/2020",
    "purpose": "Bloodwork to be done",
    "notes": ["Instead of biopsy and echo, they will do bloodwork from now onwards. You Allomap of 27, Allsure of less than .12%, indicating no signs of rejection."]
  }
]

export const phoneNumbers = [
  {
    "name": "Jason Lee (NP)",
    "phoneNumber": "First try: 212-305-9640 || Then try: 212-305-8828",
    "email": "jl2532@cumc.columbia.edu"
  },
  {
    "name": "Prompt Care Infusion",
    "phoneNumber": "732-692-2793",
  },
  {
    "name": "Cardiac Office",
    "phoneNumber": "212-305-7600",
  },
  {
    "name": "Christianne Calderon (NP)",
    "phoneNumber": "646-990-0873",
  },
  {
    "name": "Nurse Sophie",
    "phoneNumber": "212-342-3627",
  },
  {
    "name": "Dr. Lin",
    "phoneNumber": "646-317-2603",
  },
  {
    "name": "Transplant Coordinator",
    "phoneNumber": "212-305-9970",
  },
]

export const exercises = [
  {
    "name": "Pillow exercise",
    "purpose": "Keep breathing channels clear.",
    "description": "Hug pillow, look to the right, cough on purpose.",
    "frequency": "5x a day",
  },
  {
    "name": "Breathing cylinder",
    "purpose": "Strengthen lungs.",
    "description": "Breathe in through nose, blow out through mouth into tube for 10 seconds as hard as you can.",
    "frequency": "Before breakfast, lunch & dinner",
  },
  {
    "name": "Counting breaths",
    "purpose": "Avoid daydreaming and increasing awareness of heartbeats.",
    "description": "Count breaths -- in, out -- 3 times, from 1 to 10.",
    "frequency": "After waking up, before going to bed.",
  },
  {
    "name": "Leg exercises",
    "purpose": "Keep blood flow in legs steady.",
    "description": "{Need info}",
    "frequency": "{Need info}",
  },
]
// **************************************************************************
// export const pendingMedications = [
// ]
